<template>
  <div class="setting-shop-user-page main-setting-shop">
    <div class="row" ref="userModal">
      <div class="col-6 page-header">
        {{ lbl['user-permissions-add-newuser'] }}
      </div>
      <div class="col-6 d-flex justify-end">
        <p>{{ lbl['user-permissions-brand-id'] }}: {{ brandId }}</p>
        <p class="ml-3">
          {{ lbl['user-permissions-terminal'] }}: {{ terminalId }}
        </p>
      </div>
    </div>

    <!-- role -->
    <div class="row mt-4">
      <div class="col-12">
        <div class="field-label">
          {{ lbl['user-permissions-role'] }}
          <a-tooltip
            slot="suffix"
            id="tooltipClipboard"
            :title="lbl['user-permissions-role-tooltip']"
          >
            <img
              :src="require('@/assets/images/accountsetup/info.svg')"
              alt=""
            />
          </a-tooltip>
        </div>
        <div class="radioGroup">
          <Radio
            v-for="(item, index) in radioInput"
            :key="item.label + index"
            :onChangeFunction="onChangeRadio"
            :thisvalue="item.value"
            :label="item.label"
            group="Role"
            :thisId="item.value"
            :checked="formData.role"
          />
        </div>
      </div>
    </div>

    <!-- firstname lastname -->
    <div class="row mt-3">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <a-form-item>
          <div class="field-label">{{ lbl['user-permissions-firstname'] }}</div>
          <a-input
            v-model="formData.firstName"
            :placeholder="lbl['user-permissions-firstname']"
            type="text"
            class="form-control w-100"
          />
        </a-form-item>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <a-form-item>
          <div class="field-label">{{ lbl['user-permissions-lastname'] }}</div>
          <a-input
            v-model="formData.lastName"
            :placeholder="lbl['user-permissions-lastname']"
            type="text"
            class="form-control w-100"
          />
        </a-form-item>
      </div>
    </div>

    <!-- username -->
    <div class="row mt-3">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <a-form-item>
          <div class="field-label">{{ lbl['user-permissions-username'] }}</div>
          <a-input
            v-model="formData.userName"
            :placeholder="lbl['user-permissions-username']"
            type="text"
            :max-length="20"
            class="form-control w-100"
          />
          <p class="danger">{{ usernameDanger }}</p>
        </a-form-item>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="detail">
          <p :class="[vMinMaxLength('userName') ? 'pass' : '']">
            {{ lbl['user-permissions-username-min-max-charactor-validate'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vMinMaxLength('userName') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vSymbolsLowerCase('userName') ? 'pass' : '']">
            {{ lbl['user-permissions-username-eng-lowercase-validate'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vSymbolsLowerCase('userName') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
        </div>
      </div>
    </div>

    <!-- password -->
    <div class="row mt-3">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <a-form-item>
          <div class="field-label">{{ lbl['user-permissions-password'] }}</div>
          <a-input-password
            v-model="formData.password"
            type="password"
            :max-length="20"
            :placeholder="lbl['user-permissions-password']"
            @keypress="isNumber($event)"
          />
          <a-input-password
            v-model="formData.confirmPassword"
            type="password"
            :max-length="20"
            :placeholder="lbl['user-permissions-cfpassword']"
            class="mt-4"
            @keypress="isNumber($event)"
          />
          <p v-if="!passwordMatchState" class="danger">
            {{ lbl['user-permissions-passwordnotmatch'] }}
          </p>
        </a-form-item>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="detail" v-if="formData.role === 'staff'">
          <p>
            {{ lbl['user-permissions-password-validate-1'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vNoSpaceMinMaxLength('password')
                    ? 'check_circle'
                    : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vSymbolsTh('password') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-password-validate-5'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vSymbolsTh('password') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
        </div>
        <div class="detail" v-if="formData.role !== 'staff'">
          <p>
            {{ lbl['user-permissions-password-min-max-charactor-validate'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vNoSpaceMinMaxLength('password')
                    ? 'check_circle'
                    : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vCharCase('password') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-password-validate-3'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vCharCase('password') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vContainNumber('password') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-password-validate-4'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vContainNumber('password') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vSymbols('password') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-password-validate-5'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vSymbols('password') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vSymbolsTh('password') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-password-validate-5'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vSymbolsTh('password') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vSymbolsEn('password') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-password-validate-5'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vSymbolsEn('password') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
          <p :class="[vSymbolsEnLow('password') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-password-validate-5'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vSymbolsEnLow('password') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>
        </div>
      </div>
    </div>

    <!-- email mobile  -->
    <div class="row mt-3">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <a-form-item>
          <div class="field-label">{{ lbl['user-permissions-email'] }}</div>
          <a-input
            v-model="formData.email"
            :placeholder="lbl['user-permissions-email']"
            type="text"
            class="form-control w-100"
          />
          <p v-if="!emailState && emailState !== null" class="danger">
            {{ lbl['user-permissions-emailvalidate'] }}
          </p>
          <p :class="[vSymbolsTh('email') ? 'pass' : '', 'd-none']">
            {{ lbl['user-permissions-emailvalidate'] }}
            <img
              :src="
                require(`@/assets/images/${
                  vSymbolsTh('email') ? 'check_circle' : 'cross_red'
                }.png`)
              "
              alt=""
            />
          </p>

          <p class="danger">{{ emaildup }}</p>
        </a-form-item>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <a-form-item>
          <div class="field-label">{{ lbl['user-permissions-phone'] }}</div>
          <a-input
            v-model="formData.contactNumber"
            :placeholder="lbl['user-permissions-phone']"
            type="text"
            :max-length="phoneMaxlength"
            @keypress="isNumber($event)"
            class="form-control w-100"
          />
          <p v-if="!phoneState && phoneState !== null" class="danger">
            {{ lbl['user-permissions-phonevalidate'] }}
          </p>
        </a-form-item>
      </div>
    </div>

    <!-- branch  -->
    <div class="row mt-3">
      <div class="col-12">
        <div class="field-label">
          {{ lbl['account-setup-assign-to-branch'] }}
        </div>
        <div class="checkBoxContainer">
          <div class="d-flex">
            <div class="thisCheckBox" @click="onClickCheckBox('allBranch')">
              <CheckBox
                label="All Branch"
                valuee="allBranch"
                :normalCheck="
                  branchList.every((d, i) => d.ID === formData.branchIds[i])
                "
              />
            </div>
          </div>

          <div class="grid-container">
            <div
              class="grid-item"
              v-for="(item, index) in branchList"
              @click="onClickCheckBox(item.ID)"
              :key="item.Name + index"
            >
              <CheckBox
                :label="item.Name + ' (' + item.ID + ')'"
                :valuee="item.ID.toString()"
                class=""
                :normalCheck="formData.branchIds.includes(item.ID)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <hr />
        <b-button
          class="btn-back float-left d-flex justify-center align-center"
          @click="handleBack"
        >
          <a-icon type="left" />
          {{ lbl['btn_back'] }}
        </b-button>
        <b-button
          variant="warning"
          class="btn-submit float-right"
          :disabled="!formState"
          @click="postCreateUser()"
        >
          {{ lbl['account-setup-button-save'] }}
        </b-button>
      </div>
    </div>

    <UserDetailModal
      :isUserDetailModal="isUserDetailModal"
      :toggle="toggleUserDetailModal"
      :UserDetail="UserDetail"
      :copyToClipBoard="copyToClipBoard"
      :brandId="brandId"
      :terminaId="terminalId"
      :title="userTitle"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import CheckBox from '@/module/UserPermission/components/CheckBox.vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Radio from '@/module/UserPermission/components/Radio.vue'
import BzbsUserPermission from '@/core/UserPermission/service/BzbsUserPermission'
import UserDetailModal from '@/module/UserPermission/components/UserDetailModal'
import BzbsSettingsStore from '@/core/Settings/Store/BzbsSettingsStore'

export default {
  mixins: [Mixin],
  components: {
    Radio,
    CheckBox,
    UserDetailModal,
  },
  props: {
    isSetup: Boolean,
  },
  data() {
    return {
      isDisabled: false,
      brandId: '',
      terminalId: '',
      storeName: '',
      radioInput: [
        { label: 'Admin', value: 'admin' },
        { label: 'Operator', value: 'operator' },
        { label: 'Staff', value: 'staff' },
      ],
      formData: {
        active: true,
        role: 'admin',
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        confirmPassword: '',
        email: '',
        contactNumber: '',
        branchIds: [],
      },
      phoneMaxlength: 10,
      branchList: [],
      emaildup: '',
      usernameDanger: '',
      isUserDetailModal: false,
      UserDetail: {},
      userTitle: '',
    }
  },
  computed: {
    userNameState() {
      return (
        this.vSymbolsTh('userName') &&
        this.vSymbolsLowerCase('userName') &&
        this.vMinMaxLength('userName')
      )
    },
    passwordMatchState() {
      return this.formData.password === this.formData.confirmPassword
    },
    passwordState() {
      if (this.mode === 'edit' && !this.isEditPassword) return true
      if (this.formData.password === '') return null
      if (this.formData.role === 'staff')
        return (
          this.vMinLength('password') &&
          this.vSymbolsTh('password') &&
          this.vSymbolsEn('password') &&
          this.vSymbolsEnLow('password')
        )
      if (this.formData.password !== this.formData.confirmPassword) {
        return false
      }
      return this.vMinLength('password') && this.vSymbolsTh('password')
    },
    emailState() {
      if (this.formData.email === '') return null
      var rex =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return rex.test(this.formData.email) && this.vSymbolsTh('email')
    },
    phoneState() {
      if (this.formData.contactNumber === '') return null
      return (
        this.formData.contactNumber.length ===
        Vue.bzbsConfig.businessRule.max.phoneLength
      )
    },
    branchState() {
      if (this.branchList.length < 1) return true
      return this.formData.branchIds.length > 0
    },
    formState() {
      return (
        this.passwordState &&
        this.phoneState &&
        this.emailState &&
        this.branchState &&
        this.userNameState
      )
    },
  },
  watch: {
    isSetup: function () {
      this.init()
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'create-user',
    })
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  mounted() {
    this.getBranchList()
  },
  methods: {
    init() {
      this.isDisabled = this.isSetup
      this.terminalId = Vue.bzbsConfig.businessRule.default.terminalId
      this.phoneMaxlength = Vue.bzbsConfig.businessRule.max.phoneLength
      this.getCRMPlusProfile()
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    genDataProfile(data) {
      this.storeName = data.BusinessName
      this.brandId = data.EwalletBrandId
    },
    getBranchList() {
      BzbsProfile.getCRMPlusProfile()
        .then(res => {
          this.branchList = res.data.Stores
          this.formData.branchIds = this.branchList.map(item => item.ID)
        })
        .catch(err => console.log(err))
    },
    vMinLength(type) {
      return this.formData[type].length >= 6
    },
    vMaxLength(type) {
      return this.formData[type].length <= 20 && this.formData[type].length > 0
    },
    vMinMaxLength(type) {
      return this.formData[type].length <= 20 && this.formData[type].length >= 6
    },
    vNoSpaceMinMaxLength(type) {
      return (
        this.formData[type].length <= 20 &&
        this.formData[type].length >= 6 &&
        !/[\s]/.test(this.formData[type])
      )
    },
    vContainNumber(type) {
      return this.formData[type].split('').some(i => !isNaN(i))
    },
    vSymbols(type) {
      let format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
      return format.test(this.formData[type])
    },
    vSymbolsTh(type) {
      let format = /([ก-๙])/
      console.log('vSymbols Th: ', type, !format.test(this.formData[type]))
      return !format.test(this.formData[type])
    },
    vSymbolsEn(type) {
      let format = /([A-Z])/
      return !format.test(this.formData[type])
    },
    vSymbolsEnLow(type) {
      let format = /([a-z])/
      return !format.test(this.formData[type])
    },
    vSymbolsLowerCase(type) {
      let format = /(^[a-z0-9]+$)/
      return format.test(this.formData[type])
    },
    vCharCase(type) {
      let upper = this.formData[type].split('').some(i => {
        if (!isNaN(i)) return false
        return (
          i === i.toUpperCase() &&
          i.charCodeAt(0) >= 65 &&
          i.charCodeAt(0) <= 90
        )
      })

      let lower = this.formData[type].split('').some(i => {
        if (!isNaN(i)) return false
        return (
          i === i.toLowerCase() &&
          i.charCodeAt(0) >= 97 &&
          i.charCodeAt(0) <= 122
        )
      })
      return upper && lower
    },
    isNumber(evt) {
      evt = evt ? evt : window.event
      let charCode = evt.which ? evt.which : evt.keyCode
      if (this.form.role === 'staff') {
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault()
        } else {
          return true
        }
      } else {
        return true
      }
    },
    onChangeRadio(value) {
      this.formData.role = value.target.value
      if (this.formData.role === 'staff') {
        this.formData.password = ''
        this.formData.confirmPassword = ''
        const pasteBox = document.getElementById('paste-no-event')
        pasteBox.onpaste = e => {
          e.preventDefault()
          return false
        }
        const pasteBoxCF = document.getElementById('paste-no-event-cfpassword')
        pasteBoxCF.onpaste = e => {
          e.preventDefault()
          return false
        }
      }
    },
    onClickCheckBox(value) {
      if (value === 'allBranch') {
        if (
          this.branchList.every((d, i) => d.ID === this.formData.branchIds[i])
        ) {
          this.formData.branchIds = []
        } else {
          this.formData.branchIds = this.branchList.map(item => item.ID)
        }
      } else {
        if (this.formData.branchIds.some(i => i === value)) {
          return (this.formData.branchIds = this.formData.branchIds.filter(
            i => i !== value,
          ))
        } else {
          this.formData.branchIds.push(value)
          if (this.branchList.length === this.formData.branchIds.length) {
            this.formData.branchIds = this.branchList.map(item => item.ID)
          }
        }
      }
    },
    copyToClipBoard(obj, type) {
      if (type) {
        navigator.clipboard.writeText(
          `UserName : ${obj.UserName}\r\n Password : ${obj.Password}\r\n Brand Id : ${this.brandId}\r\n Terminal Id : ${this.terminalId}\r\n Branches : ${obj.Branches}\r\n`,
        )
      } else {
        navigator.clipboard.writeText(
          `UserName : ${obj.UserName}\r\n Brand Id : ${this.brandId}\r\n Terminal Id : ${this.terminalId}\r\n Branches : ${obj.Branches}\r\n`,
        )
      }
    },
    scrollToTop() {
      this.$refs.userModal.scrollTop = 0
    },
    onCloseModal() {
      this.usernameDanger = ''
      this.emaildup = ''
      this.scrollToTop()
    },
    postCreateUser() {
      this.handleLoading(true)
      BzbsUserPermission.postCRMPlusCreateUser(this.formData)
        .then(res => {
          console.log(res)
          let user = {
            UserName: this.formData.userName,
            Password: this.formData.password,
            BrandId: this.brandId,
            TerminalId: this.terminalId,
            Branches: this.formData.branchIds,
          }
          this.onCloseModal()
          this.postShopSetting()
          setTimeout(() => {
            this.toggleUserDetailModal(true, user, 'User Created!')
          }, 500)
        })
        .catch(err => {
          console.log(err.message)
          if (
            err.response.data.error.message ==
            'This username has already been taken.'
          ) {
            this.usernameDanger = this.lbl['user-permissions-usernametaken']
          }
          if (err.response.data.error.message == 'DuplicateEmail') {
            this.emaildup = this.lbl['user-permissions-dupemail']
          }
        })
        .finally(() => {
          this.handleLoading(false)
        })
    },
    toggleUserDetailModal(type, data, title, pass) {
      console.log('toggleUserDetailModal : ', type, data, title, pass)
      if (data) this.UserDetail = data
      this.userTitle = title
      this.isUserDetailModal = type
      if (pass) {
        this.$router.push({ name: 'Dashboard' })
      }
    },
    postShopSetting() {
      let obj = {
        accountcompleted: true,
        brandcompleted: true,
        connectcompleted: true,
        usercompleted: true,
      }
      return new Promise(resolve => {
        BzbsSettingsStore.postShopSetting(obj)
          .then(res => {
            if (res.data.success) {
              this.$emit('changeConnect', true)
            }
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    handleBack() {
      this.$emit('goBackUser', true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/setup_main.scss';

.setting-shop-user-page {
  .detail {
    margin: 36px 0 0 0;
  }

  .danger {
    color: $color-red-52;
  }

  .checkBoxContainer {
    margin-top: 20px;
    .grid-container {
      display: grid;
      grid-template-columns: auto auto auto auto;
      padding: 10px 0;
    }
    .grid-item {
      cursor: pointer;
      padding: 10px 0;
      font-size: 30px;
      text-align: left;
    }
  }
}
</style>
