import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
import moment from 'moment'

/**
 * @method getConnectLine
 * @method postConnectLine
 * @method postConnectFacebook
 * @method unConnectFacebook
 * @method getCampaignListUrl
 * @method getCampaignListAll
 * @method getActiveCampaignListUrl
 * @method getLineRichMenu
 * @method postLineRichMenu
 * @method postGenerateLiff
 */

export default {
  config: function () {
    return Vue.bzbsConfig
  },
  agency_id: function () {
    return Cache.get('CRMUser').AgencyId
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  app_id: function () {
    return Cache.get('CRMUser').AppId
  },
  getConnectLine: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/line' +
      '?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsGet(strUrl, this.token(), params)
  },
  postConnectLine: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/line' +
      '?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postConnectFacebook: function (params) {
    var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/facebook'
    var data = {
      agencyId: this.agency_id(),
      facebookAppId: params.facebookAppId,
    }
    return BzbsApi.connectBzbsPost(strUrl, this.token(), data)
  },
  unConnectFacebook: function () {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/facebook' +
      '?agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsDelete(strUrl, this.token(), null)
  },
  getCampaignListUrl: function () {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusburn?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getCampaignListAll: function () {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusburn/' +
      this.agency_id() +
      '/campaing_all?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getActiveCampaignListUrl: function () {
    console.log('agency_id :: ', this.agency_id())
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusburn?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&searchstatus=active'
    return BzbsApi.connectBzbsGet(strUrl, this.token(), null)
  },
  getLineRichMenu: function () {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/richmenu?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id() +
      '&v=' +
      moment().unix()
    return BzbsApi.connectBzbsGet(strUrl, this.token())
  },
  postLineRichMenu: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/richmenu?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id()

    params.generatenewbackground = true

    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postGenerateLiff: function (params) {
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/liff?device_app_id=' +
      this.config().client_id +
      '&agencyId=' +
      this.agency_id()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
}
