var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"setting-shop-account-page main-setting-shop"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',{staticClass:"field-item"},[_c('div',{staticClass:"group"},[_c('strong',{staticClass:"fz-16"},[_vm._v(_vm._s(_vm.lbl['account-setup-campaign-mode']))]),_c('span',{staticClass:"ml-2 can-not-edit"},[_vm._v(_vm._s(_vm.lbl['account-setup-can-not-edit']))])]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'radio_mode',
              {
                initialValue: _vm.radio_mode,
              } ]),expression:"[\n              'radio_mode',\n              {\n                initialValue: radio_mode,\n              },\n            ]"}],staticClass:"wrapper-radio-custom",attrs:{"disabled":_vm.isDisabled}},[_c('a-radio',{staticClass:"radio-main",attrs:{"value":'point'}},[_vm._v(" "+_vm._s(_vm.lbl['plan-reward-plan-mode-point'])+" ")]),_c('a-radio',{staticClass:"radio-main",attrs:{"value":'stamp'}},[_vm._v(" "+_vm._s(_vm.lbl['plan-reward-plan-mode-stamp'])+" ")])],1)],1)],1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',{staticClass:"field-item"},[_c('div',{staticClass:"group"},[_c('strong',{staticClass:"fz-16"},[_vm._v(_vm._s(_vm.lbl['account-setup-consumer-login-type']))]),_c('span',{staticClass:"ml-2 can-not-edit"},[_vm._v(_vm._s(_vm.lbl['account-setup-can-not-edit']))])]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'radio_type',
              {
                initialValue: _vm.radio_type,
              } ]),expression:"[\n              'radio_type',\n              {\n                initialValue: radio_type,\n              },\n            ]"}],staticClass:"wrapper-radio-custom",attrs:{"disabled":_vm.isDisabled}},[_c('a-radio',{staticClass:"radio-main",attrs:{"value":'line',"disabled":_vm.isPH}},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-login-type-line'])+" ")]),_c('a-radio',{staticClass:"radio-main",attrs:{"value":'email',"disabled":_vm.isPH}},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-login-type-email'])+" ")])],1)],1)],1)]),_c('hr',{staticClass:"mt-0"}),_c('div',{staticClass:"row d-flex justify-content-between"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',[_c('strong',{staticClass:"fz-16"},[_vm._v(_vm._s(_vm.lbl['account-setup-your-business-name']))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'business_name',
              {
                initialValue: _vm.business_name,
                rules: [
                  {
                    required: true,
                    message: '',
                  } ],
              } ]),expression:"[\n              'business_name',\n              {\n                initialValue: business_name,\n                rules: [\n                  {\n                    required: true,\n                    message: '',\n                  },\n                ],\n              },\n            ]"}],staticClass:"form-control w-100",attrs:{"disabled":_vm.isDisabled,"type":"text"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('a-form-item',{staticClass:"field-item"},[_c('strong',{staticClass:"fz-16"},[_vm._v(_vm._s(_vm.lbl['account-setup-upload-your-logo']))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 field-upload"},[_c('div',{staticClass:"box-img",class:{
                  'box-img-border': _vm.isPreview,
                  'none-border': _vm.imgPreview,
                }},[(!_vm.imgPreview)?_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/blank-photo.png"),"alt":""}}):_c('img',{staticClass:"w-100 h-100",attrs:{"src":_vm.imgPreview,"alt":""}})]),_c('div',{staticClass:"box-upload"},[_c('div',{staticClass:"btn-bg-upload",attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){return _vm.uploadArtWork($event)}}},[_c('input',{ref:"uploadImg",attrs:{"disabled":_vm.isDisabled,"type":"file","accept":"image/jpeg, image/png, image/jpg","hidden":""},on:{"change":function($event){return _vm.onImgFileChange($event)}}}),_c('img',{attrs:{"src":require("@/assets/images/accountsetup/account_upload.svg"),"alt":"","width":"24","height":"24"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-upload-image'])+" ")])]),_c('div',{staticClass:"box-recommended"},[_c('div',[_vm._v(" "+_vm._s(_vm.lbl['account-setup-the-recommended'])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.lbl['account-setup-upload-file-recommended'])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.sizeUpload)+" ")])])])])])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',{staticClass:"field-item"},[_c('strong',{staticClass:"fz-16"},[_vm._v(_vm._s(_vm.lbl['account-setup-business-type']))]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'business_type',
              {
                initialValue: _vm.business_type,
                rules: [
                  {
                    required: true,
                    message: '',
                  } ],
              } ]),expression:"[\n              'business_type',\n              {\n                initialValue: business_type,\n                rules: [\n                  {\n                    required: true,\n                    message: '',\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"size":"large"},on:{"change":function (e) {
                _vm.changeBusinessType(e)
              }}},_vm._l((_vm.optionBusinessType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('hr'),(!_vm.isDisabled)?_c('b-button',{ref:"submit",staticClass:"btn-submit float-right d-flex justify-center align-center",attrs:{"variant":"warning","html-type":"submit","type":"submit"}},[_vm._v(" "+_vm._s(_vm.lbl['account-setup-save-and-next'])+" "),_c('a-icon',{attrs:{"type":"right"}})],1):_c('b-button',{staticClass:"btn-submit float-right d-flex justify-center align-center",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.goNext()}}},[_vm._v(" "+_vm._s(_vm.lbl['user-level-button-next'])+" "),_c('a-icon',{attrs:{"type":"right"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }