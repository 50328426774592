<template>
  <div class="container-fluid setting-shop-page main-setting-shop">
    <Header :is-hide-menu="true" />
    <div class="mx-auto content-outer-container bg-none">
      <div class="box-step">
        <a-steps
          v-model="currentStep"
          type="navigation"
          @change="
            e => {
              handleStep(e)
            }
          "
        >
          <a-step
            :status="status1"
            :title="lbl['account-setup-company-detail']"
          />
          <a-step
            :status="status2"
            :title="lbl['account-setup-hq-and-branch']"
            :disabled="passStep < 1"
          />
          <a-step
            :status="status3"
            :title="lbl['account-setup-line-link']"
            :disabled="passStep < 2"
          />
          <a-step
            :status="status4"
            :title="lbl['account-setup-create-user']"
            :disabled="passStep < 3"
          />
        </a-steps>
      </div>
    </div>
    <div class="mx-auto mt-4 content-outer-container">
      <div class="mx-auto mainContainer">
        <div v-if="currentStep == 1">
          <Branch
            :is-setup="isBrandSetting"
            @changeBranch="changeBranch($event)"
            @goNextBranch="goNextBranch($event)"
            @goBackBranch="goBackBranch($event)"
          />
        </div>
        <div v-else-if="currentStep == 2">
          <Connect
            :is-setup="isConnectSetting"
            @changeConnect="changeConnect($event)"
            @goNextConnect="goNextConnect($event)"
            @goBackConnect="goBackConnect($event)"
          />
        </div>
        <div v-else-if="currentStep == 3">
          <User
            :is-setup="isUserSetting"
            @changeUser="changeUser($event)"
            @goBackUser="goBackUser($event)"
          />
        </div>
        <div v-else>
          <Account
            :is-setup="isAccountSetting"
            @changeAccount="changeAccount($event)"
            @goNextAccount="goNextAccount($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Account from '../components/Account.vue'
import Branch from '../components/Branch.vue'
import Connect from '../components/Connect.vue'
import User from '../components/User.vue'
import BzbsSettingsStore from '@/core/Settings/Store/BzbsSettingsStore'

export default {
  mixins: [Mixin],
  components: {
    Header,
    Account,
    Branch,
    Connect,
    User,
  },
  data() {
    return {
      currentStep: 0,
      passStep: 0,
      status1: 'process',
      status2: 'wait',
      status3: 'wait',
      status4: 'wait',
      isAccountSetting: false,
      isBrandSetting: false,
      isConnectSetting: false,
      isUserSetting: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.handleFooter(true)
      this.checkStep()
    },
    handleStep(e) {
      this.currentStep = e
    },
    checkStep() {
      this.getShopSetting().then(res => {
        console.log('getShopSetting : ', res.data)
        if (res.data) {
          if (
            res.data.AccountSettingCompleted &&
            !res.data.BrandSettingCompleted &&
            !res.data.ConnectSettingCompleted &&
            !res.data.UserSettingCompleted
          ) {
            //Step 2
            this.isAccountSetting = res.data.AccountSettingCompleted
            this.isBrandSetting = res.data.BrandSettingCompleted
            this.isConnectSetting = res.data.ConnectSettingCompleted
            this.isUserSetting = res.data.UserSettingCompleted
            this.status1 = 'finish'
            this.status2 = 'process'
            this.status3 = 'wait'
            this.status4 = 'wait'
            this.passStep = 1
            this.currentStep = 1
          } else if (
            res.data.AccountSettingCompleted &&
            res.data.BrandSettingCompleted &&
            !res.data.ConnectSettingCompleted &&
            !res.data.UserSettingCompleted
          ) {
            //Step 3
            this.isAccountSetting = res.data.AccountSettingCompleted
            this.isBrandSetting = res.data.BrandSettingCompleted
            this.isConnectSetting = res.data.ConnectSettingCompleted
            this.isUserSetting = res.data.UserSettingCompleted
            this.status1 = 'finish'
            this.status2 = 'finish'
            this.status3 = 'process'
            this.status4 = 'wait'
            this.passStep = 2
            this.currentStep = 2
          } else if (
            res.data.AccountSettingCompleted &&
            res.data.BrandSettingCompleted &&
            res.data.ConnectSettingCompleted &&
            !res.data.UserSettingCompleted
          ) {
            //Step 4
            this.isAccountSetting = res.data.AccountSettingCompleted
            this.isBrandSetting = res.data.BrandSettingCompleted
            this.isConnectSetting = res.data.ConnectSettingCompleted
            this.isUserSetting = res.data.UserSettingCompleted
            this.status1 = 'finish'
            this.status2 = 'finish'
            this.status3 = 'finish'
            this.status4 = 'process'
            this.passStep = 3
            this.currentStep = 3
          } else if (
            res.data.AccountSettingCompleted &&
            res.data.BrandSettingCompleted &&
            res.data.ConnectSettingCompleted &&
            res.data.UserSettingCompleted
          ) {
            //Pass All Step
            this.isAccountSetting = res.data.AccountSettingCompleted
            this.isBrandSetting = res.data.BrandSettingCompleted
            this.isConnectSetting = res.data.ConnectSettingCompleted
            this.isUserSetting = res.data.UserSettingCompleted
            this.status1 = 'finish'
            this.status2 = 'finish'
            this.status3 = 'finish'
            this.status4 = 'finish'
            this.passStep = 3
            this.currentStep = 3

            this.$router.push({ name: 'Dashboard' })
          } else {
            //Step 1
            this.isAccountSetting = res.data.AccountSettingCompleted
            this.isBrandSetting = res.data.BrandSettingCompleted
            this.isConnectSetting = res.data.ConnectSettingCompleted
            this.isUserSetting = res.data.UserSettingCompleted
            this.status1 = 'process'
            this.status2 = 'wait'
            this.status3 = 'wait'
            this.status4 = 'wait'
            this.passStep = 0
            this.currentStep = 0
          }
        }
      })
    },
    getShopSetting() {
      return new Promise(resolve => {
        BzbsSettingsStore.getShopSetting()
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            resolve(error)
          })
      })
    },
    changeAccount() {
      this.checkStep()
    },
    goNextAccount() {
      this.currentStep = 1
    },
    changeBranch() {
      this.checkStep()
    },
    goNextBranch() {
      this.currentStep = 2
    },
    goBackBranch() {
      this.currentStep = 0
    },
    changeConnect() {
      this.checkStep()
    },
    goNextConnect() {
      this.currentStep = 3
    },
    goBackConnect() {
      this.currentStep = 1
    },
    changeUser() {
      this.checkStep()
    },
    goBackUser() {
      this.currentStep = 2
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';
.setting-shop-page {
  .mainContainer {
    padding: 30px;
    @media only screen and (max-width: 460px) {
      padding: 6px;
    }
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #f80000;
  }
  .box-step {
    padding: 10px 50px;
  }
  .bg-none {
    background-color: initial;
    box-shadow: initial;
  }
}
</style>

<style lang="scss">
@import '@/style/base/common.scss';
.setting-shop-page {
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: $color-white;
    border-color: $primary;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon {
    border-color: $primary;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: $primary;
  }
  .ant-steps-navigation .ant-steps-item::before {
    background-color: $primary;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: $primary;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: $color-black-d9;
  }

  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-title,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-subtitle,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-description {
    color: $primary;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: $primary;
    border-color: $primary;
  }
  span.ant-steps-icon {
    .anticon {
      vertical-align: 0em;
    }
  }
  .ant-steps-item.ant-steps-item-wait.ant-steps-item-disabled {
    cursor: no-drop;
  }
  //   .ant-steps-item-icon > .ant-steps-icon {
  //     color: $primary;
  //   }
}
</style>