import Vue from 'vue';
import BzbsApi from '@/core/Http/BzbsApi.js';
import Cache from '@/helper/Cache.js';
/**
 * @method getCRMPlusStaff
 * @method getCRMPlusAdmin
 */
export default {
	config: function() {
		return Vue.bzbsConfig;
	},
	token: function() {
		return Cache.get('CRMUser').Token;
	},
	agencyId: function() {
		return Cache.get('CRMUser').AgencyId;
	},
	getCRMPlusUserList: function(data, type, filter) {
		const params = {
			device_app_id: this.config().client_id,
			agencyId: this.agencyId(),
			active: data.active,
			branchId: data.branchId,
			branchName: data.branchName,
			crmPlusRole: type,
			name: filter,
			email: filter
		};
		var strUrl = this.config().bzbsModuleUrl + '/crmplusadmin';
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},
	getCRMPlusStaffUserList: function(data, filter) {
		const params = {
			device_app_id: this.config().client_id,
			agencyId: this.agencyId(),
			active: data.active,
			branchId: data.branchId,
			branchName: data.branchName,
			name: filter,
			email: filter
		};
		var strUrl = this.config().bzbsModuleUrl + '/crmplusstaff';
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},
	getCRMPlusStoreInfo: function data() {
		const params = {
			device_app_id: this.config().client_id,
			agencyId: this.agencyId()
		};

		var strUrl = this.config().bzbsModuleUrl + '/crmplusoffice/profile';
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},
	postCRMPlusCreateUser: function data(data) {
		let isOperator = false;
		let newStaffRole = false;
		if (data.role === 'operator') {
			isOperator = true;
			newStaffRole = false;
		} else if (data.role === 'admin') {
			isOperator = false;
			newStaffRole = false;
		} else {
			newStaffRole = true;
		}
		const params = {
			...data,
			device_app_id: this.config().client_id,
			agencyId: this.agencyId(),
			isOperator,
			newStaffRole
		};

		var strUrl = this.config().bzbsModuleUrl + '/crmplusadmin?agencyId=' + this.agencyId();
		return BzbsApi.connectBzbsPost(strUrl, this.token(), params);
	},
	postCRMPlusUpdateUser: function data(data) {
		console.log(data);
		let params = {};
		if (data.role) {
			params = {
				...data,
				device_app_id: this.config().client_id,
				agencyId: this.agencyId(),
				crmPlusRole: data.role,
				isDeleted: false
			};
		} else {
			params = {
				...data,
				device_app_id: this.config().client_id,
				agencyId: this.agencyId(),
				isDeleted: false
			};
		}
		let id = data.userNameApi;
		if (data.oldRole === 'staff') {
			id = data.PartitionKey;
		}
		var strUrl =
			this.config().bzbsModuleUrl + '/crmplus' + data.oldRole + '/' + id + '?agencyId=' + this.agencyId();

		//if id is "xxx.y" then error url. by Achira
		if (id.includes('.') && !data.PartitionKey.includes('.')) {
			id = data.PartitionKey;
			strUrl =
				this.config().bzbsModuleUrl +
				'/crmplus' +
				data.oldRole +
				'/' +
				id +
				'?agencyId=' +
				this.agencyId() +
				'&check_by_pk=true';
		}

		return BzbsApi.connectBzbsPost(strUrl, this.token(), params);
	},
	postCRMPlusDeleteUser: function data(data) {
		const params = {
			...data,
			device_app_id: this.config().client_id,
			agencyId: this.agencyId(),
			crmPlusRole: data.role,
			isDeleted: true
		};
		let id = data.userNameApi;
		if (data.oldRole === 'staff') {
			id = data.PartitionKey;
		}
		var strUrl =
			this.config().bzbsModuleUrl + '/crmplus' + data.oldRole + '/' + id + '?agencyId=' + this.agencyId();

		//if id is "xxx.y" then error url.
		if (id.includes('.') && !data.PartitionKey.includes('.')) {
			id = data.PartitionKey;
			strUrl =
				this.config().bzbsModuleUrl +
				'/crmplus' +
				data.oldRole +
				'/' +
				id +
				'?agencyId=' +
				this.agencyId() +
				'&check_by_pk=true';
		}
		return BzbsApi.connectBzbsPost(strUrl, this.token(), params);
	}
};
