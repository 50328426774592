<template>
  <div class="setting-shop-connect-page main-setting-shop">
    <a-form :form="form" @submit="handleSubmit">
      <a-skeleton v-if="!loginType" active />
      <div v-else>
        <div v-if="loginType == 'email'">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                    <div class="col-md-12 pl-4">
                      <div class="select-link mb-3">
                        {{ lbl['link-setup-select-link-from'] }}
                      </div>
                      <span class="pl-2 pr-5"
                        ><a-radio-group
                          v-model="radio"
                          class="wrapper-radio-custom"
                          @change="changeRadio"
                        >
                          <a-radio class="pr-5 radio-main" :value="1">
                            {{ lbl['line-channel-connection-page'] }}
                          </a-radio>
                          <a-radio class="pr-5 radio-main" :value="2">{{
                            lbl['line-channel-connection-campaign']
                          }}</a-radio>
                        </a-radio-group></span
                      >
                    </div>
                  </div>

                  <div class="row px-2 py-2 pt-3">
                    <div class="col-md-12">
                      <a-form-item class="field-item">
                        <!-- Page -->
                        <a-select
                          v-if="isPage"
                          v-decorator="[
                            'connectionPage',
                            {
                              initialValue: this.page,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="
                            e => {
                              changePage(e)
                            }
                          "
                        >
                          <a-select-option
                            v-for="(item, index) in connection.page"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connection.page }}
                        </a-select>

                        <!-- Campaign -->
                        <a-select
                          v-if="isCampaign"
                          v-decorator="[
                            'connectionCampaign',
                            {
                              initialValue: this.campaign,
                              rules: [
                                {
                                  required: true,
                                  message: '',
                                },
                              ],
                            },
                          ]"
                          size="large"
                          :placeholder="
                            this.lbl['miscellaneous-please-select-option']
                          "
                          @change="
                            e => {
                              changeCampaign(e)
                            }
                          "
                        >
                          <a-select-option
                            v-for="(item, index) in connection.campaign"
                            :key="index"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                          {{ connection.campaign }}
                        </a-select>
                      </a-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4 mb-5">
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
              <div class="page-languages">
                <img
                  v-if="!isPH"
                  :src="require('@/assets/images/flag-th.png')"
                  alt=""
                  class="flag-languages"
                />
                <img
                  v-if="isPH"
                  :src="require('@/assets/images/flag-ph.png')"
                  alt=""
                  class="flag-languages"
                />
                {{ lbl['link-setup-link-as-thai'] }}
              </div>
              <div class="input-group text-center mt-3">
                <input
                  class="form-control text-center"
                  id="LinkUrlTh"
                  name="LinkUrlTh"
                  readonly="readonly"
                  type="text"
                  :value="urlLinkTh"
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <button
                    class="input-group-text copy-web-hook-url"
                    data-toggle="tooltip"
                    title=""
                    data-clipboard-target="#LinkUrlTh"
                    @click="copyClipboardTh"
                    @mouseover="showTooltipTh"
                  >
                    <a-tooltip
                      slot="suffix"
                      id="tooltipClipboard"
                      :title="titleTooltipTh"
                    >
                      <img
                        :src="require('@/assets/images/content_copy.svg')"
                        class="content-copy"
                        alt=""
                      />
                      {{ lbl['line-channel-connect-copy-button'] }}
                    </a-tooltip>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
              <div class="page-languages">
                <img
                  :src="require('@/assets/images/flag-en.png')"
                  class="flag-languages"
                  alt=""
                />
                {{ lbl['link-setup-link-as-english'] }}
              </div>
              <div class="input-group text-center mt-3">
                <input
                  class="form-control text-center"
                  id="LinkUrlEn"
                  name="LinkUrlEn"
                  readonly="readonly"
                  type="text"
                  :value="urlLinkEn"
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <button
                    class="input-group-text copy-web-hook-url"
                    data-toggle="tooltip"
                    title=""
                    data-clipboard-target="#LinkUrlEn"
                    @click="copyClipboardEn"
                    @mouseover="showTooltipEn"
                  >
                    <a-tooltip
                      slot="suffix"
                      id="tooltipClipboard"
                      :title="titleTooltipEn"
                    >
                      <img
                        :src="require('@/assets/images/content_copy.svg')"
                        class="content-copy"
                        alt=""
                      />
                      {{ lbl['line-channel-connect-copy-button'] }}
                    </a-tooltip>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr />
              <b-button
                v-if="!isDisabled"
                variant="warning"
                class="btn-submit float-right"
                html-type="submit"
                ref="submit"
                type="submit"
              >
                {{ lbl['account-setup-save-and-next'] }}
              </b-button>
              <b-button
                v-else
                variant="warning"
                class="btn-submit float-right"
                @click="goNext()"
              >
                {{ lbl['user-level-button-next'] }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-else>
          <!-- AlertSuccessLine -->
          <b-alert
            variant="success"
            dismissible
            fade
            :show="showAlertSuccessLine"
            @dismissed="showAlertSuccessLine = false"
          >
            {{ lbl['line-channel-connect-success-message'] }}
          </b-alert>

          <!-- AlertErrorLine -->
          <b-alert
            variant="danger"
            dismissible
            fade
            :show="showAlertErrorLine"
            @dismissed="showAlertErrorLine = false"
          >
            {{ lbl['line-channel-connect-error-message'] }}
          </b-alert>

          <!-- AlertSuccess -->
          <b-alert
            variant="success"
            dismissible
            fade
            :show="showAlertSuccess"
            @dismissed="showAlertSuccess = false"
          >
            {{
              lbl['line-channel-connect-regenerate-liff-url-success-message']
            }}
          </b-alert>

          <!-- AlertError -->
          <b-alert
            variant="danger"
            dismissible
            fade
            :show="showAlertError"
            @dismissed="showAlertError = false"
          >
            {{ lbl['Line Liff has already generated.'] }}
          </b-alert>

          <div class="row">
            <div class="col-12 page-header">
              {{ lbl['line-channel-connect-page-title'] }}
              <span class="sub-header ml-1"
                >({{ lbl['account-setup-optional'] }})</span
              >
            </div>
          </div>
          <div class="row field-row py-2">
            <div
              class="
                col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                field-label
                col-form-label
                field-required
              "
            >
              {{ lbl['line-channel-connect-line-message-channel-id'] }}
            </div>
            <div class="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <input
                    v-model="line_message_channel_id"
                    type="text"
                    class="form-control"
                    @change="changeInput"
                    :placeholder="
                      lbl[
                        'line-channel-connect-line-message-channel-id-placeholder'
                      ]
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row field-row py-2">
            <div
              class="
                col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                field-label
                col-form-label
                field-required
              "
            >
              {{ lbl['line-channel-connect-line-message-channel-secret'] }}
            </div>
            <div class="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <input
                    v-model="line_message_channel_secret"
                    type="text"
                    class="form-control"
                    @change="changeInput"
                    :placeholder="
                      lbl[
                        'line-channel-connect-line-message-channel-secret-placeholder'
                      ]
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row field-row py-2">
            <div
              class="
                col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                field-label
                col-form-label
                field-required
              "
            >
              {{ lbl['line-channel-connect-line-login-channel-id'] }}
            </div>
            <div class="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <input
                    v-model="line_login_channel_id"
                    type="text"
                    class="form-control"
                    @change="changeInput"
                    :placeholder="
                      lbl[
                        'line-channel-connect-line-login-channel-id-placeholder'
                      ]
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row field-row py-2">
            <div
              class="
                col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12
                field-label
                col-form-label
                field-required
              "
              style="border-bottom-width: 1px"
            >
              {{ lbl['line-channel-connect-line-login-channel-secret'] }}
            </div>
            <div
              class="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12"
              style="border-bottom-width: 1px"
            >
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <input
                    v-model="line_login_channel_secret"
                    type="text"
                    class="form-control"
                    @change="changeInput"
                    :placeholder="
                      lbl[
                        'line-channel-connect-line-login-channel-secret-placeholder'
                      ]
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row field-row mt-3">
            <div class="col-12">
              <div class="text-left">
                <p class="text-muted small mt-2">
                  {{ lbl['line-channel-connect-webhook-detail'] }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
              <a-button
                class="btn-orange-outline d-flex"
                :disabled="disabledsubmit"
                html-type="submit"
                ref="submit"
                type="submit"
              >
                {{ lbl['line-channel-connect-connect-button'] }}
              </a-button>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12">
              <div class="input-group text-center">
                <input
                  :placeholder="lbl['line-channel-connect-webhook-placeholder']"
                  class="form-control text-center"
                  id="WebHookUrl"
                  name="WebHookUrl"
                  readonly="readonly"
                  type="text"
                  :value="urlWebhook"
                  aria-invalid="false"
                />
                <div class="input-group-append">
                  <button
                    class="input-group-text copy-web-hook-url"
                    data-toggle="tooltip"
                    title=""
                    data-clipboard-target="#WebHookUrl"
                    @click="copyClipboard"
                    @mouseover="showTooltip"
                  >
                    <a-tooltip
                      slot="suffix"
                      id="tooltipClipboard"
                      :title="titleTooltip"
                    >
                      {{ lbl['line-channel-connect-copy-button'] }}
                    </a-tooltip>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <hr />
              <b-button
                class="btn-back float-left d-flex justify-center align-center"
                @click="handleBack"
              >
                <a-icon type="left" />
                {{ lbl['btn_back'] }}
              </b-button>
              <b-button
                v-if="!isDisabled"
                variant="warning"
                class="
                  btn-submit
                  float-right
                  d-flex
                  justify-center
                  align-center
                "
                @click="postShopSetting()"
              >
                {{ lbl['account-setup-save-and-next'] }}
                <a-icon type="right" />
              </b-button>
              <b-button
                v-else
                variant="warning"
                class="
                  btn-submit
                  float-right
                  d-flex
                  justify-center
                  align-center
                "
                @click="goNext()"
              >
                {{ lbl['user-level-button-next'] }}
                <a-icon type="right" />
              </b-button>
            </div>
          </div>

          <b-modal
            ref="my-modal-confirm"
            modal-class="modal-Confirm"
            :modalClass="[currentLocale]"
            centered
            hide-header
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
          >
            <div class="modal-base">
              <div class="row mt-3">
                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
                  <div class="modal-base-head">
                    <img src="@/assets/images/Clipboard-check.svg" alt="" />
                    {{
                      lbl['line-channel-connect-regenerate-liff-confirm-title']
                    }}
                  </div>
                  <div class="modal-base-body mt-4">
                    {{ lbl['line-channel-connect-line-confirm-message'] }}
                  </div>
                  <div class="modal-base-body mt-2 text-danger">
                    *{{
                      lbl[
                        'line-channel-connect-regenerate-liff-confirm-message2'
                      ]
                    }}
                  </div>
                </div>
              </div>
              <div class="modal-base-footer mt-4">
                <button
                  class="btn btn-warning ml-2"
                  @click="callApiConnectLine"
                >
                  {{
                    lbl['line-channel-connect-regenerate-liff-confirm-title']
                  }}
                </button>
                <button class="btn btn-warning ml-2 cancel" @click="hideModal">
                  {{ lbl['line-channel-connect-regenerate-liff-cancel-title'] }}
                </button>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'
import BzbsSettingsStore from '@/core/Settings/Store/BzbsSettingsStore'
import Locale from '@/helper/locale.js'

export default {
  mixins: [Mixin],
  props: {
    isSetup: Boolean,
  },
  data() {
    return {
      currentLocale: Locale.getLocaleShort(),
      loginType: null,
      radio: 1,
      page: '',
      campaign: '',
      isPage: true,
      isCampaign: false,
      connection: {
        page: [],
        campaign: [],
      },
      urlLinkTh: '',
      titleTooltipTh: '',
      urlLinkEn: '',
      titleTooltipEn: '',
      host: window.location.origin,
      isPH: false,
      line_message_channel_id: '',
      line_message_channel_secret: '',
      line_login_channel_id: '',
      line_login_channel_secret: '',
      titleTooltip: '',
      disabledsubmit: true,
      urlWebhook: '',
      showAlertSuccess: false,
      showAlertError: false,
      showAlertSuccessLine: false,
      showAlertErrorLine: false,
      appId: null,
    }
  },
  watch: {
    isSetup: function () {
      this.init()
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'create-connect',
    })
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  methods: {
    init() {
      this.isDisabled = this.isSetup
      console.log('isDisabled : ', this.isDisabled)
      this.getCRMPlusProfile()
    },
    config: function () {
      return Vue.bzbsConfig
    },
    app_id: function () {
      return Cache.get('CRMUser').AppId
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    genDataProfile(data) {
      console.log('genDataProfile : ', data)
      this.loginType = data.LoginType ? data.LoginType.toLowerCase() : null
      this.appId = this.app_id() ? this.app_id() : data.AppId

      if (this.loginType == 'email') {
        this.getPageList()
        this.getCampaignList()
        this.titleTooltipTh =
          this.lbl['line-channel-connect-webhook-tooltip-before-copy']
        this.titleTooltipEn =
          this.lbl['line-channel-connect-webhook-tooltip-before-copy']
        if (Vue.bzbsConfig.businessRule.webFor.PH) this.isPH = true
      } else {
        //  if (data.LineMessageChannelId != null) {
        //   this.line_message_channel_id = data.LineMessageChannelId
        // }
        // if (data.LineMessageSecretKey != null) {
        //   this.line_message_channel_secret = data.LineMessageSecretKey
        // }
        // if (data.LineLoginChannelId != null) {
        //   this.line_login_channel_id = data.LineLoginChannelId
        // }
        // if (data.LineLoginSecretKey != null) {
        //   this.line_login_channel_secret = data.LineLoginSecretKey
        // }
        // this.getConnectLine()
        // setTimeout(() => this.changeInput(), 300)
      }
    },
    //#region Email
    changeRadio(e) {
      if (e.target.value == 2) {
        this.isPage = false
        this.isCampaign = true
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getCampaignList()
      } else {
        this.isPage = true
        this.isCampaign = false
        this.page = ''
        this.campaign = ''
        this.connection.campaign = []
        this.connection.page = []
        this.getPageList()
      }
    },
    getPageList() {
      let data = [
        {
          Id: 'home',
          Name: this.lbl['line-channel-select-home'],
        },
        {
          Id: 'profile',
          Name: this.lbl['line-channel-select-profile'],
        },
        {
          Id: 'myrewards',
          Name: this.lbl['line-channel-select-myrewards'],
        },
      ]

      const selected = _.map(data, e => {
        return { id: e.Id, name: e.Name }
      })
      this.connection.page = selected
    },
    getCampaignList() {
      return new Promise(resolve => {
        BzbsSettingsLine.getCampaignListUrl()
          .then(res => {
            console.log('getCampaignListUrl', res)
            const selected = _.map(res.data, e => {
              return { id: e.ID, name: e.Name }
            })
            this.connection.campaign = selected
            resolve(res)
          })
          .catch(error => {
            console.log('getCampaignListUrl error', error)
            resolve(error)
          })
      })
    },
    changePage(e) {
      console.log('changePage : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH)
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/' +
          e +
          '?app_id=' +
          this.appId +
          '&locale=en'

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=en'
    },
    changeCampaign(e) {
      console.log('changeCampaign : ', e)
      this.urlLinkTh =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=th'

      //Modify by Achira on 202208021444 U-314410
      if (Vue.bzbsConfig.businessRule.webFor.PH)
        this.urlLinkTh =
          this.config().bzbsConsumerUrl +
          '/detail/' +
          e +
          '?app_id=' +
          this.appId +
          '&locale=en'

      this.urlLinkEn =
        this.config().bzbsConsumerUrl +
        '/detail/' +
        e +
        '?app_id=' +
        this.appId +
        '&locale=en'
    },
    copyClipboardTh() {
      var copyText = document.getElementById('LinkUrlTh')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltipTh() {
      this.titleTooltipTh =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    copyClipboardEn() {
      var copyText = document.getElementById('LinkUrlEn')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltipEn() {
      this.titleTooltipEn =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    //#endregion Email

    //#region Line
    copyClipboard() {
      var copyText = document.getElementById('WebHookUrl')
      navigator.clipboard.writeText(copyText.value)
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-after-copy']
    },
    showTooltip() {
      this.titleTooltip =
        this.lbl['line-channel-connect-webhook-tooltip-before-copy']
    },
    changeInput() {
      if (
        this.line_message_channel_id != '' &&
        this.line_message_channel_id != null &&
        this.line_message_channel_secret != '' &&
        this.line_message_channel_secret != null &&
        this.line_login_channel_id != '' &&
        this.line_login_channel_id != null &&
        this.line_login_channel_secret != '' &&
        this.line_login_channel_secret != null
      ) {
        this.disabledsubmit = false
      } else {
        this.disabledsubmit = true
      }
    },
    hideModal() {
      this.$refs['my-modal-confirm'].hide()
    },
    getConnectLine() {
      this.handleLoading(true)

      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {
        lineMessageChannelId: this.line_message_channel_id,
        lineMessageSecretKey: this.line_message_channel_secret,
        lineLoginChannelId: this.line_login_channel_id,
        lineLoginSecretKey: this.line_login_channel_secret,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.getConnectLine(params)
          .then(res => {
            this.handleLoading(false)
            this.urlWebhook = res.data.webHookUrl
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getConnectLine error', error)
            resolve(error)
          })
      })
    },
    callApiConnectLine() {
      this.handleLoading(true)
      this.showAlertSuccessLine = false
      this.showAlertErrorLine = false
      this.showAlertSuccess = false
      this.showAlertError = false
      let params = {
        lineMessageChannelId: this.line_message_channel_id,
        lineMessageSecretKey: this.line_message_channel_secret,
        lineLoginChannelId: this.line_login_channel_id,
        lineLoginSecretKey: this.line_login_channel_secret,
      }
      return new Promise(resolve => {
        BzbsSettingsLine.postConnectLine(params)
          .then(res => {
            this.handleLoading(false)
            this.$refs['my-modal-confirm'].hide()
            this.urlWebhook = res.data.webHookUrl
            if (res.status == 200) {
              this.showAlertSuccessLine = true
            }
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.showAlertErrorLine = true
            console.log('postConnectLine error', error)
            this.$refs['my-modal-confirm'].hide()
            resolve(error)
          })
      })
    },
    //#endregion Line

    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Line : ', values)
          if (this.loginType == 'email') {
            this.postShopSetting()
          } else {
            this.$refs['my-modal-confirm'].show()
          }
        }
      })
    },
    postShopSetting() {
      let obj = {
        accountcompleted: true,
        brandcompleted: true,
        connectcompleted: true,
        usercompleted: false,
      }
      return new Promise(resolve => {
        BzbsSettingsStore.postShopSetting(obj)
          .then(res => {
            if (res.data.success) {
              this.$emit('changeConnect', true)
            }
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    goNext() {
      this.$emit('goNextConnect', true)
    },
    handleBack() {
      this.$emit('goBackConnect', true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/setup_main.scss';

.modal-Confirm {
  .modal-body {
    padding: 1.5rem 2rem;

    .modal-base-head {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
      .icoTitle {
        width: 44px;
        height: 44px;
        padding: 5px 10px 10px 10px;
        border-radius: 4px;
        background-color: rgba(76, 175, 80, 0.1);
      }
    }

    .modal-base-body {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.15px;
      text-align: left;
      color: #616161;
    }

    .modal-base-footer {
      text-align: end;
    }

    .btn-warning {
      color: #ffffff;
      background-color: #ffc107;
      border-color: #ffc107;

      &:hover {
        color: #ffffff;
        background-color: #ecb100;
        border-color: #ecb100;
      }
      &.cancel {
        color: #ffc107;
        background-color: #fff;
        border-color: #ffc107;
        &:hover {
          color: #ffffff;
          background-color: #ecb100;
          border-color: #ecb100;
        }
      }
    }
  }
}
</style>
