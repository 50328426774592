<template>
  <div class="setting-shop-branch-page main-setting-shop">
    <a-form :form="form" @submit="handleSubmit">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item>
            <strong class="fz-16">{{
              lbl['account-setup-head-quarter']
            }}</strong>
            <a-input
              v-decorator="[
                'head_quarter',
                {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                },
              ]"
              :disabled="isDisabled"
              type="text"
              class="form-control w-100"
            />
          </a-form-item>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item>
            <strong class="fz-16">{{
              lbl['account-setup-telephone-number']
            }}</strong>
            <a-input
              v-decorator="[
                'contact_number',
                {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                },
              ]"
              :disabled="isDisabled"
              class="w-100"
              :max-length="maxPhoneLength"
              @keypress="onlyNumber"
            >
            </a-input>
          </a-form-item>
        </div>
      </div>

      <!-- Select your location -->
      <div class="row">
        <div class="col-12 mb-3">
          <strong class="fz-16">{{
            lbl['account-setup-select-your-location']
          }}</strong>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
              <a-button
                :disabled="isDisabled"
                class="btn-map d-flex"
                @click="openMap"
              >
                <img
                  src="@/assets/images/accountsetup/map.svg"
                  alt=""
                  width="24"
                  height="24"
                />
                <span class="ml-2">
                  {{ lbl['account-setup-open-maps'] }}
                </span>
              </a-button>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-12">
              <label class="lbl-manual d-flex"
                >{{ lbl['account-setup-or-add-manual'] }}
                <img
                  src="@/assets/images/accountsetup/info.svg"
                  alt=""
                  class="ml-1"
              /></label>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
              <a-form-item>
                <a-input
                  v-decorator="[
                    'latitude',
                    {
                      initialValue: latitude,
                      rules: [
                        {
                          required: true,
                          message: '',
                        },
                      ],
                    },
                  ]"
                  :disabled="isDisabled"
                  prefix="lat : "
                  type="text"
                  class="w-100"
                  @keypress="onlyNumberDecimal"
                  @change="changeLatitude"
                />
              </a-form-item>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
              <a-form-item>
                <a-input
                  v-decorator="[
                    'longtitude',
                    {
                      initialValue: longtitude,
                      rules: [
                        {
                          required: true,
                          message: '',
                        },
                      ],
                    },
                  ]"
                  :disabled="isDisabled"
                  prefix="lng : "
                  type="text"
                  class="w-100"
                  @keypress="onlyNumberDecimal"
                  @change="changeLongtitude"
                />
              </a-form-item>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-1 pb-2">
        <div class="col-12">
          <b-button
            variant="warning"
            block
            class="btn-submit w-100"
            html-type="submit"
            type="submit"
            :disabled="isDisabled"
          >
            {{ lbl['account-setup-add-branch'] }}
          </b-button>
        </div>
      </div>

      <hr class="bw-3" />

      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <strong class="fz-16">
            {{ lbl['company-setup-branch-name'] }}
          </strong>
          <span class="upgrade-required">
            * {{ lbl['account-setup-upgrade-plan-required'] }}
          </span>
        </div>

        <div class="col-12">
          <a-table
            row-key="RowKey"
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            :showHeader="false"
          >
            <template slot="date" slot-scope="text">
              {{ dateShortWithTimeFormat(text) }}
            </template>
            <template slot="isname" slot-scope="text, record">
              <a-input
                v-if="
                  editableData == record.key && record.name != 'Headquarter'
                "
                v-model="dataSource[record.key - 1].name"
                style="margin: -5px 0"
              />
              <span v-else>
                {{ text ? text : '-' }}
              </span>
            </template>
            <template slot="iscontact_number" slot-scope="text, record">
              <a-input
                v-if="editableData == record.key"
                v-model="dataSource[record.key - 1].contact_number"
                style="margin: -5px 0"
                :max-length="10"
                @keypress="onlyNumber"
              />
              <span v-else>
                {{ text ? text : '-' }}
              </span>
            </template>
            <template slot="latlng" slot-scope="text, record">
              <a-input-group v-if="editableData == record.key" compact>
                <a-input
                  v-model="dataSource[record.key - 1].latitude"
                  style="margin: -5px 0; width: 50%"
                  @keypress="onlyNumberDecimal"
                />
                <a-input
                  v-model="dataSource[record.key - 1].longtitude"
                  style="margin: -5px 0; width: 50%"
                  @keypress="onlyNumberDecimal"
                />
              </a-input-group>

              <span v-else>
                {{ record.latitude ? record.latitude : 0 }} /
                {{ record.longtitude ? record.longtitude : 0 }}
              </span>
            </template>
            <template slot="action" slot-scope="text, record">
              <div class="d-flex box-action">
                <a
                  v-if="editableData == record.key"
                  :disabled="isDisabled"
                  type="primary"
                  class="btn-action-branch"
                  @click="() => onSave(record.key)"
                >
                  {{ lbl['account-setup-button-save'] }}
                </a>
                <a
                  v-else
                  :disabled="isDisabled"
                  type="primary"
                  class="btn-action-branch"
                  @click="() => onEdit(record.key)"
                >
                  {{ lbl['account-setup-button-edit'] }}
                </a>

                <a-tooltip
                  v-if="record.name != 'Headquarter' && record.import != true"
                  placement="top"
                >
                  <template slot="title"
                    ><div>
                      {{ lbl['smart-target-delete'] }}
                    </div></template
                  >
                  <a class="mx-3 mb-2" :disabled="isDisabled">
                    <a-icon
                      :style="{ color: '#ff5252', fontSize: '18px ' }"
                      theme="filled"
                      type="delete"
                      @click="onDelete(record.key)"
                    />
                  </a>
                </a-tooltip>
              </div>
            </template>
          </a-table>
        </div>
      </div>

      <!-- Footer Button -->
      <div class="row mt-5">
        <div class="col-12">
          <hr />
          <b-button
            class="btn-back float-left d-flex justify-center align-center"
            @click="handleBack"
          >
            <a-icon type="left" />
            {{ lbl['btn_back'] }}
          </b-button>
          <b-button
            v-if="!isDisabled"
            variant="warning"
            class="btn-submit float-right d-flex justify-center align-center"
            @click="handleSaveAndNext"
          >
            {{ lbl['account-setup-save-and-next'] }}
            <a-icon type="right" />
          </b-button>
          <b-button
            v-else
            variant="warning"
            class="btn-submit float-right d-flex justify-center align-center"
            @click="goNext()"
          >
            {{ lbl['user-level-button-next'] }}
            <a-icon type="right" />
          </b-button>
        </div>
      </div>
    </a-form>

    <GoogleMap
      :show="showGoogleMap"
      @changeCloseGoogleMap="changeCloseGoogleMap($event)"
      @changeSubmitGoogleMap="changeSubmitGoogleMap($event)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import GoogleMap from '@/components/Maps/GoogleMap.vue'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Vue from 'vue'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import BzbsSettingsStore from '@/core/Settings/Store/BzbsSettingsStore'

const columns = [
  {
    dataIndex: 'name',
    align: 'left',
    scopedSlots: {
      customRender: 'isname',
    },
  },
  {
    dataIndex: 'contact_number',
    align: 'left',
    scopedSlots: {
      customRender: 'iscontact_number',
    },
    width: '30%',
  },
  {
    dataIndex: 'latitude',
    align: 'left',
    scopedSlots: {
      customRender: 'latlng',
    },
    width: '30%',
  },
  {
    dataIndex: 'key',
    align: 'center',
    scopedSlots: {
      customRender: 'action',
    },
    width: '120px',
  },
]

export default {
  mixins: [Mixin],
  components: {
    GoogleMap,
  },
  props: {
    isSetup: Boolean,
  },
  data() {
    return {
      optionCountry: [],
      country: '+66',
      showGoogleMap: false,
      latitude: '',
      longtitude: '',
      columns,
      dataSource: [],
      editableData: '',
      params: {},
      storeType: '',
      maxPhoneLength: 10,
      userData: {},
      isDisabled: false,
    }
  },
  watch: {
    isSetup: function () {
      this.init()
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'create-branch',
    })
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  methods: {
    init() {
      this.isDisabled = this.isSetup
      this.maxPhoneLength = Vue.bzbsConfig.businessRule.max.phoneLength
      this.getCRMPlusProfile()
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.userData = res.data
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            window.location.reload()
            resolve(error)
          })
      })
    },
    genDataProfile(data) {
      this.storeType = data.StoreType
      this.params.agencyId = data.SponsorId
      this.params.business_name = data.BusinessName
      this.params.business_type = data.BusinessType
      if (data.Stores) {
        this.dataSource = _.map(data.Stores, e => {
          return {
            id: e.ID,
            name: e.Name,
            contact_number: e.ContactNumber,
            latitude: e.Latitude,
            longtitude: e.Longtitude,
            import: e.Import,
            isheadoffice:e.IsHeadOffice
          }
        })
        this.setKey()
      } else {
        this.dataSource = []
      }
    },
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    onlyNumberDecimal($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault()
      }
    },
    changeSelectCountry(e) {
      console.log('Country : ', e)
      this.country = e
    },
    openMap() {
      console.log('openMap')
      this.showGoogleMap = true
    },
    changeCloseGoogleMap() {
      console.log('CloseGoogleMap : ')
      this.showGoogleMap = false
    },
    changeSubmitGoogleMap(res) {
      console.log('SubmitGoogleMap lat : ', res.lat, 'lng : ', res.lng)
      this.showGoogleMap = false
      if (res) {
        this.latitude = res.lat
        this.longtitude = res.lng
        this.form.setFieldsValue({
          latitude: res.lat,
          longtitude: res.lng,
        })
      }
    },
    changeLatitude(e) {
      console.log('changeLatitude ; ', e.target.value)
      this.latitude = e.target.value
    },
    changeLongtitude(e) {
      console.log('changeLongtitude ; ', e.target.value)
      this.longtitude = e.target.value
    },
    onEdit(key) {
      console.log('onEdit : ', key)
      this.editableData = key
    },
    onSave() {
      this.editableData = null
    },
    onDelete(key) {
      console.log('onEdit : ', key)
      this.dataSource = _.filter(this.dataSource, item => {
        if (item.key != key) return item
      })

      this.setKey()
    },
    resetFields() {
      this.form.resetFields()
      this.latitude = ''
      this.longtitude = ''
    },
    setKey() {
      return new Promise(resolve => {
        var i = 1
        _.forEach(this.dataSource, item => {
          item.key = i
          i++
        })
        resolve(true)
      })
    },
    onFileChange(e) {
      let data = {
        business_name: this.userData.BusinessName,
        business_type: this.userData.BusinessType,
        email: this.userData.Email,
        district_code: this.userData.DistrictCode,
        district_name: this.userData.DistrictName,
        subdistrict_code: this.userData.SubDistrictCode,
        subdistrict_name: this.userData.SubDistrictName,
        province_code: this.userData.ProvinceCode,
        province_name: this.userData.ProvinceName,
        zipcode: this.userData.Zipcode,
        branch: JSON.stringify(this.dataSource),
        logo: this.userData.LogoUrl,
      }
      var files = e.target.files
      if (files.length) {
        return new Promise(resolve => {
          BzbsUserProfile.postUploadExcel(data, files)
            .then(res => {
              resolve(res.data)
            })
            .catch(error => {
              resolve(error)
            })
        })
      }
    },
    handleSubmit(e) {
      e.preventDefault()

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('values of form: ', values)
          this.dataSource.push({
            name: values.head_quarter,
            contact_number: values.contact_number,
            latitude: values.latitude,
            longtitude: values.longtitude,
            isheadoffice:values.head_quarter == 'Headquarter' ? true : false
          })

          this.setKey()
          this.resetFields()
        }
      })
    },
    postShopSetting() {
      let obj = {
        accountcompleted: true,
        brandcompleted: true,
        connectcompleted: false,
        usercompleted: false,
      }
      return new Promise(resolve => {
        BzbsSettingsStore.postShopSetting(obj)
          .then(res => {
            if (res.data.success) {
              this.$emit('changeBranch', true)
            }
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    goNext() {
      this.$emit('goNextBranch', true)
    },
    handleBack() {
      this.$emit('goBackBranch', true)
    },
    handleSaveAndNext() {
      this.params.branch = JSON.stringify(this.dataSource)
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.postAccountSetting(this.params)
          .then(res => {
            this.handleLoading(false)
            this.postShopSetting()
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.$warning({
              title: this.lbl['alert-box-error-header'],
              content: error.response.data.error.message,
              centered: true,
              autoFocusButton: null,
              closable: true,
              okText: this.lbl['alert-box-success-close-button'],
              onOk: () => {},
            })
            resolve(error)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/setup_main.scss';
</style>

<style lang="scss">
@import '../styles/setup_main.scss';

.setting-shop-branch-page {
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 42px;
  }
}
</style>
