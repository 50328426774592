<template>
  <div>
    <a-modal
      id="modalMaps"
      v-model="showModal"
      class="modal-Maps"
      :afterClose="mapsClose"
      :class="[currentLocale]"
    >
      <GmapMap
        :center="center"
        :zoom="12"
        map-style-id="roadmap"
        :options="mapOptions"
        style="width: 100%; height: 50vmin"
        ref="mapRef"
        @click="handleMapClick"
      >
        <GmapMarker
          :position="marker.position"
          :clickable="true"
          :draggable="true"
          @drag="handleMarkerDrag"
          @click="panToMarker"
        />
      </GmapMap>
      <!-- <button @click="geolocate">Detect Location</button>
      <p>Selected Position: {{ marker.position }}</p> -->
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          class="btn-submit-map"
          @click="handleSubmit"
          >{{ lbl['btn-submit'] }}</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'

export default {
  name: 'GoogleMap',
  mixins: [Mixin],
  props: {
    show: Boolean,
  },
  data() {
    return {
      marker: { position: { lat: 13.764783502066184, lng: 100.5020819538797 } },
      center: { lat: 13.764783502066184, lng: 100.5020819538797 },
      mapOptions: {
        disableDefaultUI: true,
      },
      showModal: false,
      currentLocale: Locale.getLocaleShort(),
    }
  },
  watch: {
    show(newVal, oldVal) {
      console.log('Prop show: ', newVal, ' | was: ', oldVal)
      if (newVal == true) {
        this.modalShow(newVal)
      }
    },
  },
  methods: {
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        this.panToMarker()
      })
    },
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position)
      this.$refs.mapRef.setZoom(18)
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      console.log(e)
    },
    modalShow(newVal) {
      this.showModal = newVal
    },
    mapsClose() {
      this.showModal = false
      this.$emit('changeCloseGoogleMap', false)
    },
    handleSubmit() {
      this.showModal = false
      this.$emit('changeSubmitGoogleMap', this.marker.position)
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/common.scss';

#modalMaps {
  .ant-modal {
    width: 50% !important;
  }

  .btn-submit-map {
    border: 1px solid $color-orange-2;
    background-color: $color-orange-2;
    padding: 0px 15px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal {
      width: 90% !important;
    }
  }
  @media only screen and (max-width: 1280px) {
    .ant-modal {
      width: 80% !important;
    }
  }
}
</style>