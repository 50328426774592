<template>
  <div>
    <div :class="['backDrop', isUserDetailModal ? 'isShow' : '']" />
    <div :class="['UserDetailModal', isUserDetailModal ? 'isShow' : '']">
      <div id="captureDetail" class="box-captrue">
        <div class="modalHeader">
          <img :src="require('@/assets/images/checkper.svg')" alt="" />
          <div class="header">{{ title }}</div>
        </div>
        <div class="subText">
          {{ lbl['user-permissions-sub-text'] }}
        </div>
        <hr class="hr-top" />
        <div class="mainDetail">
          <div class="body">
            <div class="title d-block">
              <p>{{ lbl['user-permissions-username'] }}:</p>
            </div>
            <div class="value">
              <p>{{ UserDetail.UserName }}</p>
            </div>
          </div>

          <div class="body">
            <div class="title d-block">
              <p v-if="title === 'User Created!'">
                {{ lbl['user-permissions-password'] }}:
              </p>
            </div>
            <div class="value">
              <p v-if="title === 'User Created!'">{{ UserDetail.Password }}</p>
            </div>
          </div>

          <div class="body">
            <div class="title d-block">
              <p>{{ lbl['user-permissions-brand-id'] }}:</p>
            </div>
            <div class="value">
              <p>{{ brandId }}</p>
            </div>
          </div>

          <div class="body">
            <div class="title d-block">
              <p>{{ lbl['user-permissions-terminal'] }}:</p>
            </div>
            <div class="value">
              <p>{{ terminaId }}</p>
            </div>
          </div>

          <div class="body">
            <div class="title d-block">
              <p>{{ lbl['user-permissions-branch-id'] }}:</p>
            </div>
            <div class="value">
              <p>{{ Branches }}</p>
            </div>
          </div>
          <button
            class="copyBtn"
            @click="copyToClipBoard(UserDetail, title === 'User Created!')"
          >
            <img :src="require('@/assets/images/copy.svg')" alt="" />
          </button>
        </div>
        <hr class="hr-low" />
        <div
          v-for="(item, index) in qrData"
          :key="index"
          class="information-box"
        >
          <div
            :class="{
              'col-12 p-0': index == 'Android',
              'col-12 pl-0 pt-2': index == 'IOS' || index == 'Website',
            }"
          >
            <span> <img :src="item.icon" class="iso-icon" alt="" /> </span
            ><span class="title-platform">{{ item.name }}</span>
          </div>
          <div class="box-qr">
            <div>
              <img class="qr" :src="item.image_url" alt="" />
            </div>
            <div class="text">
              <span class="title">{{ item.title }}</span>
              <div class="br" />
              <span class="detail">{{ item.detail }}</span>
              <div class="br" />
              <div class="box-website">
                <a class="website" :href="item.website" target="_blank">{{
                  item.website
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerAction">
        <div class="col-md-6 col-8 pl-0">
          <button class="copy" @click="copyImage()">
            <span
              ><img :src="require('@/assets/images/camera.svg')" alt="" /></span
            ><span v-if="copyied">{{
              lbl['third-party-message-after-copy']
            }}</span
            ><span v-else>{{ lbl['copy-as-image'] }}</span>
          </button>
        </div>
        <div class="col-md-6 col-4 text-right pr-0">
          <button class="closeBtn" @click="toggle(false, null, null, true)">
            {{ lbl['user-permissions-close'] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { copyImageToClipboard } from 'copy-image-clipboard'
Vue.use(copyImageToClipboard)
import Config from '@/helper/Config.js'
import Mixin from '@/mixin/Mixin'
import * as htmlToImage from 'html-to-image' //ref >> https://vuejscomponent.com/package?name=html-to-image

export default {
  mixins: [Mixin],
  props: {
    UserDetail: {
      type: Object,
    },
    title: {
      type: String,
      default: 'User Created!',
    },
    terminaId: {
      type: String,
    },
    brandId: {
      type: String,
    },
    isUserDetailModal: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
    },
    copyToClipBoard: {
      type: Function,
    },
  },
  data() {
    return {
      copyied: false,
      qrData: {
        Android: {
          icon: require('@/assets/images/android.png'),
          name: 'Android',
          title: 'Scan QR Code to Download Merchant App (APK)',
          detail: 'or open link to download (APK)',
          image_url:
            'https://devstoragebuzzebees.blob.core.windows.net/config/crmplus/qr-code/Android.png',
          website:
            'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
        },
        IOS: {
          icon: require('@/assets/images/ios.png'),
          name: 'IOS',
          title: 'Scan QR Code to Download Merchant App (APK)',
          detail: 'or open link to download (APK)',
          image_url:
            'https://devstoragebuzzebees.blob.core.windows.net/config/crmplus/qr-code/IOS.png',
          website:
            'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
        },
        Website: {
          icon: require('@/assets/images/website.png'),
          name: 'Website',
          title: 'Scan QR Code to Download Merchant App (APK)',
          detail: 'or open link to download (APK)',
          image_url:
            'https://devstoragebuzzebees.blob.core.windows.net/config/crmplus/qr-code/website.png',
          website:
            'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
        },
      },
    }
  },
  watch: {
    lbl(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setLocale()
      }
    },
  },
  computed: {
    Branches() {
      if (!this.UserDetail.Branches) return '-'
      return this.UserDetail.Branches.join(' ')
    },
  },
  created() {
    this.qrData = {
      Android: {
        icon: require('@/assets/images/android.png'),
        name: 'Android',
        title: 'Scan QR Code to Download Merchant App (APK)',
        detail: 'or open link to download (APK)',
        image_url:
          Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/qr-code/Android.png',
        website:
          'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
      },
      IOS: {
        icon: require('@/assets/images/ios.png'),
        name: 'IOS',
        title: 'Scan QR Code to Download Merchant App (APK)',
        detail: 'or open link to download (APK)',
        image_url:
          Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/qr-code/IOS.png',
        website:
          'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
      },
      Website: {
        icon: require('@/assets/images/website.png'),
        name: 'Website',
        title: 'Scan QR Code to Download Merchant App (APK)',
        detail: 'or open link to download (APK)',
        image_url:
          Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/qr-code/website.png',
        website:
          'https://crmplusoffice.buzzebees.com/UserProfile/UserCustomerManage',
      },
    }
    this.getConfigQrcode()
  },
  methods: {
    setLocale() {
      this.qrData.Android.name = this.lbl['android']
      this.qrData.Android.title = this.lbl['scan-qr-apk']
      this.qrData.Android.detail = this.lbl['open-link-apk']
      //
      this.qrData.IOS.name = this.lbl['ios']
      this.qrData.IOS.title = this.lbl['scan-qr-apk-ios']
      this.qrData.IOS.detail = this.lbl['open-link-apk-ios']
      //
      this.qrData.Website.name = this.lbl['website']
      this.qrData.Website.title = this.lbl['scan-qr-website']
      this.qrData.Website.detail = this.lbl['open-merchant-website']
    },
    getConfigQrcode() {
      Config.getConfigQrcode().then(res => {
        this.qrData.Android.image_url = res.qrcode[0].image_url
        this.qrData.IOS.image_url = res.qrcode[1].image_url
        this.qrData.Website.image_url = res.qrcode[2].image_url
        //
        this.qrData.Android.website = res.qrcode[0].website
        this.qrData.IOS.website = res.qrcode[1].website
        this.qrData.Website.website = res.qrcode[2].website
      })
    },
    copyImage() {
      var node = document.getElementById('captureDetail')
      htmlToImage.toJpeg(node, { quality: 1 }).then(function (dataUrl) {
        //copy to clipboard
        if (dataUrl) {
          copyImageToClipboard(dataUrl)
        }
      })
      this.copyied = true
      setTimeout(() => {
        this.copyied = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserPermission/style/userpermission.scss';
</style>
