<template>
  <div class="setting-shop-account-page main-setting-shop">
    <a-form :form="form" @submit="handleSubmit">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item class="field-item">
            <div class="group">
              <strong class="fz-16">{{
                lbl['account-setup-campaign-mode']
              }}</strong>
              <span class="ml-2 can-not-edit">{{
                lbl['account-setup-can-not-edit']
              }}</span>
            </div>
            <a-radio-group
              v-decorator="[
                'radio_mode',
                {
                  initialValue: radio_mode,
                },
              ]"
              :disabled="isDisabled"
              class="wrapper-radio-custom"
            >
              <a-radio :value="'point'" class="radio-main">
                {{ lbl['plan-reward-plan-mode-point'] }}
              </a-radio>
              <a-radio :value="'stamp'" class="radio-main">
                {{ lbl['plan-reward-plan-mode-stamp'] }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item class="field-item">
            <div class="group">
              <strong class="fz-16">{{
                lbl['account-setup-consumer-login-type']
              }}</strong>
              <span class="ml-2 can-not-edit">{{
                lbl['account-setup-can-not-edit']
              }}</span>
            </div>
            <a-radio-group
              v-decorator="[
                'radio_type',
                {
                  initialValue: radio_type,
                },
              ]"
              :disabled="isDisabled"
              class="wrapper-radio-custom"
            >
              <a-radio :value="'line'" :disabled="isPH" class="radio-main">
                {{ lbl['account-setup-login-type-line'] }}
              </a-radio>
              <a-radio :value="'email'" :disabled="isPH" class="radio-main">
                {{ lbl['account-setup-login-type-email'] }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
      </div>
      <hr class="mt-0" />

      <!-- Business Name -->
      <div class="row d-flex justify-content-between">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item>
            <strong class="fz-16">{{
              lbl['account-setup-your-business-name']
            }}</strong>
            <a-input
              v-decorator="[
                'business_name',
                {
                  initialValue: business_name,
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                },
              ]"
              :disabled="isDisabled"
              type="text"
              class="form-control w-100"
            />
          </a-form-item>
        </div>
      </div>

      <!-- Upload Your Logo -->
      <div class="row">
        <div class="col-12">
          <a-form-item class="field-item">
            <strong class="fz-16">{{
              lbl['account-setup-upload-your-logo']
            }}</strong>
            <div class="row">
              <div class="col-12 field-upload">
                <div
                  class="box-img"
                  :class="{
                    'box-img-border': isPreview,
                    'none-border': imgPreview,
                  }"
                >
                  <img
                    v-if="!imgPreview"
                    class="w-100"
                    src="@/assets/images/blank-photo.png"
                    alt=""
                  />
                  <img v-else :src="imgPreview" class="w-100 h-100" alt="" />
                </div>
                <div class="box-upload">
                  <div
                    :disabled="isDisabled"
                    class="btn-bg-upload"
                    @click="uploadArtWork($event)"
                  >
                    <input
                      @change="onImgFileChange($event)"
                      :disabled="isDisabled"
                      ref="uploadImg"
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      hidden
                    />
                    <img
                      src="@/assets/images/accountsetup/account_upload.svg"
                      alt=""
                      width="24"
                      height="24"
                    />
                    <span class="ml-2">
                      {{ lbl['account-setup-upload-image'] }}
                    </span>
                  </div>
                  <div class="box-recommended">
                    <div>
                      {{ lbl['account-setup-the-recommended'] }}
                    </div>
                    <div>
                      {{ lbl['account-setup-upload-file-recommended'] }}
                    </div>
                    <div>
                      {{ sizeUpload }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>
        </div>
      </div>

      <!-- Business Type -->
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item class="field-item">
            <strong class="fz-16">{{
              lbl['account-setup-business-type']
            }}</strong>
            <a-select
              v-decorator="[
                'business_type',
                {
                  initialValue: business_type,
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                },
              ]"
              :disabled="isDisabled"
              size="large"
              @change="
                e => {
                  changeBusinessType(e)
                }
              "
            >
              <a-select-option
                v-for="(item, index) in optionBusinessType"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr />
          <b-button
            v-if="!isDisabled"
            variant="warning"
            class="btn-submit float-right d-flex justify-center align-center"
            html-type="submit"
            ref="submit"
            type="submit"
          >
            {{ lbl['account-setup-save-and-next'] }}
            <a-icon type="right" />
          </b-button>
          <b-button
            v-else
            variant="warning"
            class="btn-submit float-right d-flex justify-center align-center"
            @click="goNext()"
          >
            {{ lbl['user-level-button-next'] }}
            <a-icon type="right" />
          </b-button>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import fileReader from '@/helper/fileReader'
import Account from '@/helper/AccountHelper.js'
import InfiniteHelper from '@/helper/InfiniteHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsSettingsStore from '@/core/Settings/Store/BzbsSettingsStore'
import Util from '@/helper/Utility.js'

export default {
  mixins: [Mixin],
  props: {
    isSetup: Boolean,
  },
  data() {
    return {
      optionBusinessType: [],
      radio_mode: 'point',
      radio_type: 'Email',
      isPH: true,
      params: {},
      params_reward: {},
      appId: '',
      business_name: '',
      business_type: '',
      imgPreview: '',
      sizeUpload: '',
      isPreview: false,
      isDisabled: false,
    }
  },
  watch: {
    isSetup: function () {
      this.init()
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'create-account',
    })
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  methods: {
    init() {
      console.log('isSetup : ', this.isSetup)
      this.isDisabled = this.isSetup
      if (Vue.bzbsConfig.businessRule.webFor.PH) {
        this.isPH = true
      } else {
        this.isPH = false
      }

      this.sizeUpload = this.lbl[
        'account-setup-upload-size-recommended'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)

      this.getBusinessType()
      this.getCRMPlusProfile()
    },
    getBusinessType() {
      this.optionBusinessType = InfiniteHelper.businessTypeList()
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genDataProfile(res.data)
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    genDataProfile(data) {
      this.imgPreview = data.LogoUrl + '?v=' + new Date().getTime()
      this.business_name = data.BusinessName
      this.business_type = data.BusinessType
      if (Vue.bzbsConfig.businessRule.webFor.PH) {
        this.radio_type = 'Email'
      } else {
        this.radio_type = data.LoginType ? data.LoginType.toLowerCase() : 'line'
      }
      this.radio_mode = data.StoreType ? data.StoreType : 'point'
      this.params.agencyId = data.SponsorId
      this.params_reward.agencyId = data.SponsorId

      if (data.Stores) {
        let branch = _.map(data.Stores, e => {
          return {
            id: e.ID,
            name: e.Name,
            contact_number: e.ContactNumber,
            latitude: e.Latitude,
            longtitude: e.Longtitude,
            isheadoffice: e.IsHeadOffice,
          }
        })

        this.params.branch = JSON.stringify(branch)
      }

      this.appId = data.AppId
      var CRMUserCookie = Cache.get('CRMUser')
      CRMUserCookie.AppId = data.AppId
      Cache.set('CRMUser', CRMUserCookie)
    },
    uploadArtWork() {
      this.$refs.uploadImg.value = null
      this.$refs.uploadImg.click()
    },
    onImgFileChange(event) {
      var image = event.target.files[0]
      this.imgPreview = URL.createObjectURL(image)
      if (this.imgPreview) {
        this.isPreview = false
      }
    },
    changeBusinessType(e) {
      console.log('BusinessType : ', e)
      this.business_type = e
    },
    handleSubmit(e) {
      e.preventDefault()
      if (!this.imgPreview) {
        this.isPreview = true
      }
      this.form.validateFields((err, values) => {
        if (!err && this.imgPreview) {
          console.log('values : ', values)
          fileReader.getBaseImage(this.imgPreview).then(item => {
            this.params.logo = fileReader.blobToFile(item, 'LogoUrl')
            this.params.business_name = values.business_name
            this.params.business_type = values.business_type

            if (!this.isEdit) {
              this.params.loginTypeId = Util.mapLoginTypeId(this.radioLoginType)
            }

            this.handleLoading(true)
            return new Promise(resolve => {
              BzbsProfile.postAccountSetting(this.params)
                .then(res => {
                  this.setLogoUrl(res)
                  this.postReward()
                  this.handleLoading(false)
                  resolve(res.data)
                })
                .catch(error => {
                  this.handleLoading(false)
                  this.alertModalAction(
                    this.lbl['alert-box-error-header'],
                    error.response.data.error.message,
                    'error',
                    true,
                  )
                  resolve(error)
                })
            })
          })
        }
      })
    },
    setLogoUrl(res) {
      console.log('setLogoUrl : ', res.data.store.LogoUrl)
      if (res.data.store.LogoUrl) {
        var img = res.data.store.LogoUrl + '?v=' + new Date().getTime()
        Account.updateLogoUrl(img)
      }
    },
    postReward() {
      console.log('postReward')
      this.params_reward.type = this.radioCampaignMode
      return new Promise(resolve => {
        BzbsProfile.postReward(this.params_reward)
          .then(res => {
            if (res.data.Success) {
              this.postShopSetting()
            }
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              error.response.data.error.message,
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
    postShopSetting() {
      let obj = {
        accountcompleted: true,
        brandcompleted: false,
        connectcompleted: false,
        usercompleted: false,
      }
      return new Promise(resolve => {
        BzbsSettingsStore.postShopSetting(obj)
          .then(res => {
            if (res.data.success) {
              this.$emit('changeAccount', true)
            }
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    goNext() {
      this.$emit('goNextAccount', true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/setup_main.scss';
</style>
