<template>
  <div>
    <input
      type="radio"
      :id="thisId"
      :name="group"
      :value="thisvalue"
      @input="onChangeFunction($event)"
      :checked="checked === thisvalue"
      :disabled="disabled"
    />
    <label :for="thisId">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    thisvalue: {
      type: String,
      default: '',
    },
    onChangeFunction: {
      type: Function,
    },
    thisId: {
      type: String,
    },
    checked: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ff9800;
  border-radius: 100%;
  background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #ff9800;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>
